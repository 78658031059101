import { Injectable } from '@angular/core';
import { ReplaySubject, Subscription } from 'rxjs';
import { User } from '../../models/user';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

   /**
   * ReplaySubject(Tipo de observable) que irá está sincronizado com as mudanças do usuário atual.
   */
  user$: ReplaySubject<User> = new ReplaySubject<User>(1)

  /**
   * Subscription para requisição do usuário em tempo real
   */
  private subscription_user: Subscription

  /**
   * Usuário logado
   */
  user: User

  /**
   * ReplaySubject(Tipo de observable) que irá está sincronizado com as mudanças do usuário atual.
   */
  reloadHome$ = new ReplaySubject(1);

  pages = [
    {title:'Documentação Contábil', url:'/documentacao-contabil'},
    {title:'Documentação Contábil (Busca Avançada)', url:'/busca-avancada'},
    {title:'Licitações', url:'/licitacao'},
    {title:'Leis', url:'/sistema/leis'},
    {title:'Decretos', url:'/sistema/decretos'},
    {title:'Portarias', url:'/sistema/portarias'},
    {title:'Outros', url:'/sistema/outros'},
  ]

  constructor() {

  }

  log(...params){
    console.log.apply(0,params);
  }

  /**
   * Sincroniza o usuário que está no banco de dados com o observable user$
   * @param id Id do usuário
   */
  setUser(user: User) {
    if (this.subscription_user) this.subscription_user.unsubscribe()
    this.subscription_user = null
    if (!user) {
      this.user$.next(null)
      return
    }
    this.user$.next(user)
    this.subscription_user = User.object_sync(user.id).subscribe((user: User) => {
      if (user) {
        this.user$.next(user)
      } else {
        this.user$.next(null)
      }
    }, error => {
      this.user$.next(null)
      console.error("Usuário deslogado")
    })
  }

}
