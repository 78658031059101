import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { AuthService } from '../../services/auth.service';
import { SystemService } from '../../services/system.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'my-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input() title
  @Input() hideUploadButton
  @Output() onUploadButtonClick = new EventEmitter();

  user

  constructor(
    public authService: AuthService,
    public system: SystemService, 
    public alertCtrl: AlertController,
    public shared: SharedService,
    public modalCtrl: ModalController
  ) {
  }

  ngOnInit() {
    this.shared.user$.subscribe((user) => {
      this.user = user;
    })
  }

  async logout() {
    let alert = await this.alertCtrl.create({
      header: "Sair",
      message: "Você tem certeza que deseja deslogar do sistema?",
      buttons: [
        {
          text: "Não"
        },
        {
          text: "Sim",
          handler: () => {
            this.authService.logout().then((res) => {
              console.log("Deslogado com sucesso")
              this.system.navigateRoot('login')
              this.system.showToast("Seu usuário foi deslogado com sucesso");
            })
          }
        }
      ]
    })
    alert.present();
  }

  uploadFiles(){
    this.onUploadButtonClick.emit(null);
  }

}
