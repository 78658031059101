import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
})
export class FileInputComponent implements OnInit {

  @Input() buttonText;
  @Input() accept;
  @Input() disabled;
  @Input() size;
  @Input() iconName;
  @Input() multiple;
  @ViewChild('fileInput', {
    static: false
  }) fileInput;
  @Output() inputChange = new EventEmitter();

  user

  constructor() {

  }

  ngOnInit() {
    
  }

  getFile(){
    this.fileInput.nativeElement.click();
  }

  processFile(ev){
    this.inputChange.emit(ev);
  }

}
