import {
  app,
  auth,
  firestore,
  initializeApp
  } from 'firebase/app';
import {
  catchError,
  first,
  map,
  mergeMap
  } from 'rxjs/operators';
import {
  combineLatest,
  from,
  Observable,
  of
  } from 'rxjs';
// import { FIREBASE_CONFIG } from '../../main';
import { Injectable } from '@angular/core';
import { SharedService } from './shared.service';
import { User } from '../../models/user';
import 'firebase/auth';
import { FIREBASE_CONFIG } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  /**
   * Auxiliar para gerar autenticação sem redirecionamento
   */
  otherAuthenticationApp: app.App;

  /**
   * Observable que fiscaliza a autenticação uma única vez
   */
  authState$: Promise<User>;

  confirmationResult: auth.ConfirmationResult

  // recaptchaVerifier: auth.RecaptchaVerifier;

  constructor(private shared: SharedService) {
    console.log("Auth Service")
    this.otherAuthenticationApp = initializeApp(FIREBASE_CONFIG, 'otherAuthenticationApp');

    this.authState$ = this.initAuthState();
    // Gatilhar a primeira verificação de autenticação
    this.authState$.then()
  }

  async token(): Promise<string> {
    const user: firebase.User = auth().currentUser
    if (!user) return null
    return await user.getIdToken()
  }

  firebaseAuthState(): Observable<firebase.User> {
    return Observable.create((observer) => {
      auth().onAuthStateChanged(observer)
    })
  }

  /**
   * Observa mudanças na autenticação
   */
  async initAuthState(): Promise<User> {
    // Mudança no estado de autenticação do usuário.
    const firebase_user = await this.firebaseAuthState()
      .pipe(first()).toPromise()

    // Usuário logado.
    if (firebase_user) {
      const user = await User.object<User>(firebase_user.uid)
      this.shared.setUser(user)
      user.update({
        last_time_online_timestamp: firestore.Timestamp.now()
      })
      return user
    }
    // Usuário deslogado.
    else {
      this.shared.setUser(null)
      return null;
    }
  }

  /**
   * Realizar o login do usuário.
   * @param email Email do usuário.
   * @param password Senha do usuário.
   * @returns Um observable que tenta o login do usuário.
   */
  async login(email: string, password: string): Promise<User> {
    try {
      await auth().signInWithEmailAndPassword(email, password)
    } catch (err) {
      throw this.authError(err.code)
    }
    return await this.initAuthState()
  }

  /**
   * Realizar o login pelo telefone celular
   */
  async authPhoneNumber(phoneNumber): Promise<auth.ConfirmationResult> {
    var appVerifier = new auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'normal',
    });

    return await auth().signInWithPhoneNumber(phoneNumber, appVerifier)
  }

  /**
   * Realizar o logout do usuário atual.
   */
  async logout(): Promise<User> {
    await auth().signOut()
    return await this.initAuthState()
  }

  /**
   * Criar um novo usuário.
   * @param email Email do usuário.
   * @param password Senha do usuário.
   */
  async register(user: User, password: string): Promise<User> {

    try {
      const uid: string = (await auth().createUserWithEmailAndPassword(user.email, password)).user.uid
      user.id = uid
      await user.set()
      return await this.initAuthState()
    } catch (err) {
      throw this.authError(err.code)
    }
  }

  /**
   * Atualiza o "email" e "password" de autenticação
   * @param emailOld Email no início da atualização
   * @param passwordOld Password no início da atualização
   * @param email Email no final da atualização
   * @param password Password no final da atualização
   */
  update(emailOld: string, passwordOld: string, email: string, password: string): Observable<any> {

    if (emailOld == email && passwordOld == password) {
      return of("Email e password são os mesmos. Não houve alteração de autenticação");
    }

    return from(this.otherAuthenticationApp.auth().signInWithEmailAndPassword(emailOld, passwordOld)).
      pipe(mergeMap((authData: auth.UserCredential) => {
        var email$: Observable<string>;
        if (emailOld != email) {
          email$ = from(authData.user.updateEmail(email))
            .pipe(map(() => "Email de autenticação foi alterado com sucesso"))
            .pipe(catchError(() => "Erro ao tentar alterar o email de autenticação"))
        }
        var password$: Observable<string>;
        if (passwordOld != password) {
          password$ = from(authData.user.updatePassword(password))
            .pipe(map(() => "Password de autenticação foi alterado com sucesso"))
            .pipe(catchError(() => "Erro ao tentar alterar o password de autenticação"))
        }
        return combineLatest([
          (email$ || "Email não foi alterado"),
          (password$ || "Password não foi alterado"),
        ]);
      }));
  }

  /**
   * Traduz o erro do firebase
   * @param code código do error no firebase
   *
   * Error Codes (err.code)
   * auth/invalid-email
   * Thrown if the email address is not valid.
   * auth/user-disabled
   * Thrown if the user corresponding to the given email has been disabled.
   * auth/user-not-found
   * Thrown if there is no user corresponding to the given email.
   * auth/wrong-password
   * Thrown if the password is invalid for the given email, or the account corresponding to the email does not have a password set.
   * auth/email-already-in-use
   * Thrown if there already exists an account with the given email address.
   * auth/operation-not-allowed
   * Thrown if email/password accounts are not enabled. Enable email/password accounts in the Firebase Console, under the Auth tab.
   * auth/weak-password
   * Thrown if the password is not strong enough.
   * auth/invalid-password
   * O valor fornecido para a propriedade de usuário password é inválido. Precisa ser uma string com pelo menos seis caracteres.
   */
  authError(code: string): Error {
    switch (code) {
      case "auth/invalid-email":
        return Error("E-mail inválido");
      case "auth/user-disabled":
        return Error("Usuário desabilitado");
      case "auth/user-not-found":
        return Error("Usuário não encontrado");
      case "auth/wrong-password":
        return Error("Senha incorreta");
      case "auth/email-already-in-use":
        return Error("Email já está em uso");
      case "auth/weak-password":
      case "auth/invalid-password":
        return Error("Senha inválida");
      default:
        return Error("Algo de errado aconteceu. Estamos trabalhando para resolver o problema.");
    }
  }

    /**
   *
   * @param email Email do usuario
   */
  recovery(email: string) {
    return Observable.create(observer => {
      auth().sendPasswordResetEmail(email).then(() => {
        observer.next('success');
      }).catch(err => {
        observer.error(err);
      });
    });
  }

  /**
   * Cria um novo usuário. Retorna uma promise que resolve com o id do novo user
   * @param email email
   * @param password password
   */
  createNewUser(email, password){
    return this.otherAuthenticationApp.auth()
      .createUserWithEmailAndPassword(email, password).then((authData)=>{
        return this.otherAuthenticationApp.auth().signOut().then(()=>{
          return authData.user.uid;
        })
      })
  }

}
