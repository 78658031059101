import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CpfcnpjPipe } from './cpfcnpj.pipe';
import { FormatCurrencyPipe } from './format-currency.pipe';
import { SafePipe } from './safe.pipe';
import { StringNumberPipe } from './string-number.pipe';
import { NiceDateFormatPipe } from './nice-date-format.pipe';
import { DateFormatPipe } from './date-format.pipe';
import { ValidPipe } from './valid.pipe';
import { FnPipe } from './fn.pipe';
import { LimitStringPipe } from './limit-string.pipe';

@NgModule({
  declarations: [
    CpfcnpjPipe,
    FormatCurrencyPipe,
    SafePipe,
    StringNumberPipe,
    NiceDateFormatPipe,
    DateFormatPipe,
    ValidPipe,
    FnPipe,
    LimitStringPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CpfcnpjPipe,
    FormatCurrencyPipe,
    StringNumberPipe,
    SafePipe,
    DateFormatPipe,
    NiceDateFormatPipe,
    ValidPipe,
    FnPipe,
    LimitStringPipe,
  ]
})
export class PipesModule { }
