import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { first, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Role, RoleType, User } from 'src/models/user';
import { SharedService } from 'src/app/services/shared.service';
import { SystemService } from 'src/app/services/system.service';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

  constructor(public shared: SharedService, public route: Router, public system: SystemService,
    public authService: AuthService) {
    console.log("Permission Guard!")
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.shared.user$
      .pipe(first())
      .pipe(map((user: User) => {
        // console.log("Usuário: ", user);
        if (user) {
          if(user.role === 'CLIENT'){
            return true;
          } else if(user.role === 'TEAM'){
            this.route.navigateByUrl('admin')
            return false;
          } else {
            console.error('Current user has no permissions  ');
            return false;
          }
        } else {
          this.route.navigateByUrl('login')
          return false;
        }
      }))
  }
}

function checkUserRole(user: User, roles: RoleType[]): boolean {
  if (!user) return false
  if (!roles || roles.length <= 0) return true
  if (roles.indexOf(user.role) < 0) return false
  return true
}