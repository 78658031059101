import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cpfcnpj'
})
export class CpfcnpjPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    //Formatar CPF
    if (value.length == 11) {
      // let format = value.substr(0, 3) + '.' + value.substr(3, 6) + '.' + value.substr(6, 9) + '-' + value.substr(10, 12);
      // console.log("CPF formatado: ", format);
      // return value.substr(0, 3) + '.' + value.substr(3, 6) + '.' + value.substr(6, 9) + '-' + value.substr(10, 12);
      return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4");
    }

    //Formatar CNPJ
    else if (value.length == 14) {
      return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5");
    }

    console.error("Valor de CPF ou CNPJ não válido!");
    return value;

  }

}