import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { HeaderComponent } from './header/header.component';
import { FileInputComponent } from './file-input/file-input.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { HeaderAdminComponent } from './header-admin/header-admin.component';

@NgModule({
  imports: [CommonModule, IonicModule, PipesModule],
  declarations: [
    HeaderComponent,
    FileInputComponent,
    SideMenuComponent,
    HeaderAdminComponent,
  ],
  exports: [
    HeaderComponent,
    FileInputComponent,
    SideMenuComponent,
    HeaderAdminComponent,
  ],
})
export class ComponentsModule {}