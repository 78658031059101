import {Collection} from '../modules/firestore/collection';

export class User extends Collection {

    static path = 'users';

    name?: string;
    user_name?: string;
    email?: string;
    password?: string;
    phone?: string;
    cpf_cnpj?: string;
    role?: RoleType; //Permissão única
    roles?: RoleType; //Permissão múltipla
    push_notification?: { pushToken: string, userId: string };
    codigo_municipio: string;
    tipo?: 'EXE' | 'LEG'; // legistativo pode ver apenas a camara municipal, executivo pode ver todas exceto camara municipal
    [x: string]: any
}

export const Role: {
    TEAM: RoleType,
    CLIENT: RoleType, // Gestor
    MEMBER: RoleType, // Visualizador de conteúdo
} = {
    TEAM: 'TEAM',
    CLIENT: 'CLIENT',
    MEMBER: 'MEMBER',
};

export type RoleType = 'TEAM' | 'CLIENT' | 'MEMBER'
