import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {

  constructor(public router: Router, public shared: SharedService) {}

  ngOnInit() {}

  thisPageIs(str){
    return this.router.url === str;
  }

  goto(str){
    this.router.navigateByUrl(str);
  }

}
