import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatCurrency'
})
export class FormatCurrencyPipe implements PipeTransform {

  /*
    Recebe R$4,5000.50 e transforma para R$ 4.500,50
   */
  transform(value, args) {
    if(typeof value == 'string') value = value.replace(/,/g,'');
    if(typeof value == "string" && value[0] == 'R')
      return Number(value.substr(2)).toLocaleString('pt-br',{style:'currency',currency:'BRL'});
    return Number(value).toLocaleString('pt-br',{style:'currency',currency:'BRL'});
    // value = value.replace(",", "*"); // * auxiliar para substituir por um ponto
    // return ((value.replace("$", "$ ")).replace(".", ",")).replace("*", ".");
  }

}