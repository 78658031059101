import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'niceDateFormat'
})
export class NiceDateFormatPipe implements PipeTransform {

  transform(value: string) {

    let date = new Date(value);

    var _value = date.getTime();

    var dif = Math.floor(((Date.now() - _value) / 1000) / 86400);

    if (dif < 30) {
      return convertToNiceDate(value);
    } else {
      var datePipe = new DatePipe("pt-BR");
      value = datePipe.transform(value, 'MMM-dd-yyyy');
      return value;
    }
    
  }

}

function convertToNiceDate(time: string) {
  var date = new Date(time),
    diff = (((new Date()).getTime() - date.getTime()) / 1000),
    daydiff = Math.floor(diff / 86400);

  if (isNaN(daydiff) || daydiff < 0 || daydiff >= 31)
    return '';

  return daydiff == 0 && (
    diff < 60 && "Agora" ||
    diff < 120 && "1 minuto atrás" ||
    diff < 3600 && Math.floor(diff / 60) + " minutos atrás" ||
    diff < 7200 && "1 hora atrás" ||
    diff < 86400 && Math.floor(diff / 3600) + " horas atrás") ||
    daydiff == 1 && "Ontem" ||
    daydiff < 7 && daydiff + " dias atrás" ||
    daydiff < 31 && Math.ceil(daydiff / 7) + " semana(s) atrás";
}