import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AdminGuard} from './guards/admin.guard';
import {LoginGuard} from './guards/login.guard';
import {PermissionGuard} from './guards/permission.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
        canActivate: [LoginGuard],
    },
    {
        path: 'admin',
        loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminPageModule),
        canActivate: [AdminGuard],
    },
    {
        path: 'user-register',
        loadChildren: () => import('./pages/user-register/user-register.module').then(m => m.UserRegisterPageModule),
        canActivate: [AdminGuard],
    },
    {
        path: 'user-register/:id',
        loadChildren: () => import('./pages/user-register/user-register.module').then(m => m.UserRegisterPageModule),
        canActivate: [AdminGuard],
    },
    {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
        canActivate: [PermissionGuard],
    },
    {
        path: 'documentacao-contabil',
        loadChildren: () => import('./pages/documentacao-contabil/documentacao-contabil.module').then(m => m.DocumentacaoContabilPageModule),
        canActivate: [PermissionGuard],
    },
    {
        path: 'upload-files',
        loadChildren: () => import('./pages/upload-files/upload-files.module').then(m => m.UploadFilesPageModule),
        canActivate: [PermissionGuard],
    },
    {
        path: 'licitacao',
        loadChildren: () => import('./pages/licitacao/licitacao.module').then(m => m.LicitacaoPageModule),
        canActivate: [PermissionGuard],
    },
    {
        path: 'sistema/:category',
        loadChildren: () => import('./pages/sistema/sistema.module').then(m => m.SistemaPageModule),
        canActivate: [PermissionGuard],
    },
    {
        path: 'sistema/:category/send-files',
        loadChildren: () => import('./pages/send-files/send-files.module').then(m => m.SendFilesPageModule),
        canActivate: [PermissionGuard],
    },
    {
        path: 'busca-avancada',
        loadChildren: () => import('./pages/busca-avancada/busca-avancada.module').then(m => m.BuscaAvancadaPageModule),
        canActivate: [PermissionGuard],
    },
    {
        path: 'documento',
        loadChildren: () => import('./pages/documento/documento.module').then(m => m.DocumentoPageModule),
        canActivate: [PermissionGuard],
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
