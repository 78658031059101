import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fn'
})
export class FnPipe implements PipeTransform {

  transform(value: unknown, ...args: any[]): unknown {
    if(!value[args[0]] || typeof value[args[0]] != 'function') return value;
    return value[args[0]](...args.slice(1));
  }

}
