import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitString'
})
export class LimitStringPipe implements PipeTransform {

  transform(value: string, ...args: any[]): unknown {
    if(typeof value != 'string') return value;
    return value.substr(0,args[0]) + (value.length > args[0] ? '…' : '');
  }

}
