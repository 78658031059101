import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'valid'
})
export class ValidPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return value ? value : (args[0] ? args[0] : 'N/A');
  }

}
