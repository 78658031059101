import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringNumber'
})
export class StringNumberPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    return value.split(',')[0]
  }

}
