import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(date: any, format: string): any {
    if(date[7] == '-') return date.substr(0,10).split('-').reverse().join('/');
    return date.replace(/-/g, "/");
  }

}