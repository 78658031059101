import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { User } from 'src/models/user';
import { AuthService } from '../services/auth.service';
import { SharedService } from '../services/shared.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(
    public shared: SharedService,
    public route: Router,
    public authService: AuthService
  ) {
    console.log("Login Guard!")
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.shared.user$
      .pipe(first(), map((user: User) => {
        // console.log("Usuário: ", user);
        if (!user) {
          return true;
        } else {
          this.route.navigateByUrl('home')
          return false;
        }
      }))
  }
  
}
