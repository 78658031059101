import {Component, Input, OnInit} from '@angular/core';
import {AlertController} from '@ionic/angular';
import {AuthService} from 'src/app/services/auth.service';
import {SharedService} from 'src/app/services/shared.service';
import {SystemService} from 'src/app/services/system.service';

@Component({
    selector: 'my-header-admin',
    templateUrl: './header-admin.component.html',
    styleUrls: ['./header-admin.component.scss'],
})
export class HeaderAdminComponent implements OnInit {

    @Input() title: string;
    @Input() logout: any;

    constructor(public alertCtrl: AlertController,
                public authService: AuthService,
                public system: SystemService,
                public shared: SharedService) {
    }

    ngOnInit() {
        this.logout = this.logout !== undefined;
    }

    async doLogout() {
        const alert = await this.alertCtrl.create({
            header: 'Sair',
            message: 'Você tem certeza que deseja deslogar do sistema?',
            buttons: [
                {
                    text: 'Não'
                },
                {
                    text: 'Sim',
                    handler: () => {
                        this.authService.logout().then((res) => {
                            console.log('Deslogado com sucesso');
                            this.system.navigateRoot('login');
                            this.system.showToast('Seu usuário foi deslogado com sucesso');
                        });
                    }
                }
            ]
        });

        await alert.present();
    }

}
